<template>
  <v-dialog v-model="dialog" max-width="800" persistent>
    <div style="background-color: #ffffff; " class="d-flex flex-column flex-sm-row popup-forgetPass" wrap>
      <div style="background-color: #bbdefb; height: 100%"
        class="d-flex flex-column justify-center align-center popup-forgetPass-left">
        <v-img src="/images/LandingPage/Group1.png" class="ma-10 popup-forgetPass-img mt-4"></v-img>

        <v-btn icon="$close" variant="text" color="#14293C" @click="closeShowforget"
          class="position-absolute top-0 left-0 mt-md-5 ml-md-5"></v-btn>
      </div>
      <div style="height: 100%" class="d-flex flex-column justify-space-between pa-4 pa-sm-10">
        <div class="mt-2 mt-md-0 pb-8 pb-md-0">
          <h2 class="">Forgot Your Password?</h2>
        </div>
        <div class="pt-md-4 mt-n4">
          <v-form v-model="form" class="mt-sm-5 mt-n16">
            <v-text-field class="" type="email" rounded="lg" variant="outlined" placeholder="Enter Email Id"
              v-model="email" :rules="emailRules" required></v-text-field>

            <v-btn flat rounded="lg" color="rgba(58, 82, 103, 1)" type="submit" size="x-large" block
              class="text-capitalize text-body-1 mt-4" style="color: white" @click.prevent="resetPass">
              Forgot Password
            </v-btn>
          </v-form>
        </div>

        <div class="mt-4 mt-sm-auto align-end text-caption forgetPass-termsConditon" style="color: grey">
          By signing up, you agree to
          <NuxtLink to="/terms" @click="closeShowforget">
            <strong style="color: #14293c">Terms & Conditions</strong>
          </NuxtLink>
          and
          <NuxtLink to="/policy" @click="closeShowforget">
            <strong style="color: #14293c"> Privacy Policy</strong>
          </NuxtLink>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import axios from 'axios';
import mixins from "~/mixins/global";
export default {
  mixins: [mixins],
  data() {
    return {
      store: useMainStore(),
      dialog: true,
      form: false,
      email: "",
      emailRules: [
        (value) => !!value || "E-mail is required.",
        (value) => /.+@.+\..+/.test(value) || "E-mail must be valid.",
      ],
    };
  },

  mounted() {
    window.addEventListener("keydown", this.handleEsc);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleEsc);
  },
  methods: {
    // onSubmit() {
    //   console.log("Form submitted");
    // },
    closeShowforget() {
      this.$emit("closeShowforget");
    },

    handleEsc(event) {
      if (event.key === "Escape") {
        this.closeShowforget();
      }
    },

    async resetPass() {
      try {
        if (this.form == true) {
          const forgetData = {
            register_email: this.email.toLowerCase(),
          }
          // console.log("this is email", forgetData);
          const res = await this.put("reset-password-link", forgetData, { secure: false });
          console.log("this is res====", res);
        } else {
          // alert("Please Enter Valid Email");
          this.store.triggerSnackbar('Please Enter Valid Email', 'info')

        }
      } catch (err) {
        console.log("this is error", err);
        this.store.triggerSnackbar('SomeThing went wrong', 'error')
      }
    },
  },
};
</script>

<style scoped>
.forgetPass-termsConditon a {
  text-decoration: none;
}

.popup-forgetPass {
  height: 560px;
  border-radius: 10px 10px 10px 10px;
}

.popup-forgetPass-left {
  border-radius: 10px 0px 0px 10px;
}

.popup-forgetPass-img {
  width: 300px;
  height: 276px;
}

@media (max-width: 599.99px) {
  .popup-forgetPass-img {
    width: 100%;
    height: 159px;
  }

  .popup-forgetPass-left {
    border-radius: 10px 10px 0px 0px;
  }

  .popup-forgetPass {
    height: 700px;
  }
}
</style>
