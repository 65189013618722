<!-- import { BoxPaddingIcon } from 'vue-tabler-icons'; -->
<template>
  <div class="footer-page-background px-md-5">
    <v-container fluid class="footer-container">
      <!-- first card -->

      <div style="background: none">
        <v-row wrap class="footer mt-16 mx-md-3"><v-col cols="12" md="12" sm="12" xs="12">
            <v-card flat style="background: none"
              class="footer-card-1 d-md-flex d-flex-justify-space-bwtween d-flex-wrap d-sm-block">
              <div class="footer-card-1-left md-12 sm-12">
                Join Diggaj Coder
                <span class="footer-card-1-span1">Discord Community: Learn, Code, and Connect with Passionate
                  Developers!</span>
                <div class="mt-7">
                  <NuxtLink to="Community" target="_blank">
                    <v-btn size="large" class="footer-card-1-btn1" :style="{ color: '#14293c', padding: '0px 50px' }">
                      join Discord
                    </v-btn>
                  </NuxtLink>
                </div>
              </div>
              <div class="footer-card-1-vertical d-md-flex d-sm-none d-none"></div>
              <div class="footer-card-1-vertical2 w-100 my-sm-15 d-sm-flex d-md-none"></div>
              <div class="footer-card-1-right ml-md-10">
                Do you have a question?
                <span class="footer-card-1-span1">Contact us – we're here to assist!</span>
                <div class="mt-7">
                  <NuxtLink to="/ContactUs" target="_blank">
                    <v-btn size="large" class="footer-card-1-btn1" style="color: #14293c">
                      Contact us
                    </v-btn>
                  </NuxtLink>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <!-- horizontal line  -->
      <v-row wrap><v-col cols="12" md="12" sm="12" class="w-100 pt-16 mt-10 d-md-none">
          <div>
            <hr />
          </div>
        </v-col></v-row>
      <!-- second-card -->
      <div style="background: none">
        <v-row wrap>
          <v-col cols="12" md="12" sm="12" class="mt-md-16 mt-sm-0 px-md-10 w-100">
            <div class="footer-card-2 d-md-flex d-sm-block text-white">
              <ul class="py-md-0 py-sm-15 d-flex-column mobile-footers" style="list-style: none">
                <li>
                  <img src="/images/img/Diggaj Coder.png" alt="DiggajCoder" />
                </li>
                <li>Email: admissions@diggajcoder.com</li>

                <li>Phone: 9930025885</li>
                <li>Hours: Mon-Fri 10am-8pm IST</li>
                <li class="mt-16 mt-md-0">Startup India Certified</li>
                <li>
                  <img src="/images/img/Logo1 1.png" alt="StartupIndia" />
                </li>
              </ul>

              <ul class="mobile-footers ml-sm-5" style="list-style: none">
                <li style="font-weight: 700">CONTACT US</li>

                <!--                 <li>
                  Location :<br />
                  Kandivali , Mumbai , India

                </li>
 -->
                <li>We'd love to hear from you! For questions, support, or feedback, reach out to our team. We're here
                  to help on your coding journey.</li>
              </ul>
              <div class="footer-card-4-icon w-100 d-flex d-sm-none my-16">
                <NuxtLink to="https://www.linkedin.com/company/diggaj-coder/mycompany/" target="_blank">
                  <img src="public/images/img/footer/download (2).png" alt="LinkedIn" class="mr-5" />
                </NuxtLink>
                <NuxtLink to="https://x.com/DIGGAJCODER" target="_blank">
                  <img src="public/images/img/footer/download.png" alt="X" class="mr-5" />
                </NuxtLink>
                <!-- <NuxtLink to="" target="_blank">
                  <img
                    src="public/images/img/footer/download (1).png"
                    alt="FaceBook" class="mr-5"
                  />
                </NuxtLink> -->
                <NuxtLink to="https://www.instagram.com/diggajcoder/" target="_blank">
                  <img src="public/images/img/footer/download (3).png" alt="Instagram" class="mr-5" />
                </NuxtLink>
                <!-- <NuxtLink to="" target="_blank">
                  <img
                    src="public/images/img/footer/download (4).png"
                    alt="Youtube" class="mr-5"
                  />
                </NuxtLink> -->
                <NuxtLink to="https://whatsapp.com/channel/0029VadSQIHGk1FzEbX6J82G" target="_blank">
                  <img src="public/images/img/footer/download (5).png" alt="WhatsApp" />
                </NuxtLink>
              </div>
              <!-- horizontal line  -->
              <v-row wrap><v-col cols="12" md="12" sm="12" class="w-100 py-sm-5 mt-sm-0 d-md-none">
                  <div>
                    <hr />
                  </div>
                </v-col></v-row>
              <ul class="pl-md-11 txt-dec-non" style="list-style: none">
                <li style="font-weight: 700">SiteMap</li>

                <li><a href="https://diggajcoder.substack.com/" rel="noopener noreferrer" target="_blank">Blog</a></li>
                <li><a href="/ReferAndEarn" rel="noopener noreferrer" target="_blank">Refer & Earn</a></li>
                <li><a href="/Intership" rel="noopener noreferrer" target="_blank">Intership</a></li>
                <li><a href="/FAQs" rel="noopener noreferrer" target="_blank">FAQ</a></li>
                <li><a href="/ContactUs" rel="noopener noreferrer" target="_blank">Contact us</a></li>
                <li><a href="/Community" rel="noopener noreferrer">Join Discord</a></li>
                <!-- <li>Careers</li> -->
                <!-- <li>Inclusivity</li> -->
                <!-- <v-expansion-panels flat class="d-flex d-sm-none d-md-none">
                    <v-expansion-panel>
                      <v-expansion-panel-title>
                        COMPANY
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <li>About Codesmith</li>
                        <li>Blog</li>
                        <li>Careers</li>
                        <li>Inclusivity</li>
                      </v-expansion-panel-text></v-expansion-panel
                    >
                  </v-expansion-panels> -->
              </ul>
              <v-row wrap><v-col cols="12" md="12" sm="12" class="w-100 py-sm-5 mt-sm-0 d-md-none">
                  <div>
                    <hr />
                  </div>
                </v-col></v-row>
              <ul class="" style="list-style: none">
                <li style="font-weight: 700">Featured Courses</li>
                <li>Full Stack development course - Basic</li>
                <li>Full Stack development course - Advance</li>
                <li>Front-end development course</li>
              </ul>
              <v-row wrap><v-col cols="12" md="12" sm="12" class="w-100 py-sm-5 mt-sm-0 d-md-none">
                  <div>
                    <hr />
                  </div>
                </v-col></v-row>
              <ul class="" style="list-style: none">
                <li style="font-weight: 700">Community</li>
                <li>
                  Stay connected with us for the latest tips, tutorials, and industry news. At Diggaj Coder, we're
                  dedicated to helping you succeed in your tech career. Here's how you can stay up-to-date and make the
                  most of our resources
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </div>
      <!-- third card  -->

      <v-row wrap class="d-flex justify-md-end justify-sm-center pr-md-14 pt-md-2 pt-5 pb-2 footer-card-3">
        <div>
          <div class="footer-termsConditon mx-3 mx-md-0">
            <NuxtLink to="/terms">Terms & Conditions</NuxtLink>
            | <NuxtLink to="/policy"> Privacy Policy</NuxtLink>
            | <NuxtLink to="https://merchant.razorpay.com/policy/NZBiqjHAMV981r/shipping"> Support</NuxtLink>
            | <NuxtLink to="/ContactUs"> Contact us</NuxtLink>
            | <NuxtLink to="/Community">Join Discord</NuxtLink>
          </div>
        </div>
      </v-row>

      <!-- horizontal line  -->

      <v-row wrap><v-col cols="12" md="12" sm="12" class="w-100 px-md-9 pr-md-13">
          <div>
            <hr />
          </div>
        </v-col></v-row>

      <!-- forth-card  -->

      <v-row wrap class="px-sm-9">
        <v-col cols="12" md="12" class="d-flex justify-md-space-between justify-sm-space-between footer-card-4">
          <div class="text-wrap ml-md-n3 ml-sm-n8 text-center" style="color: white">
            © 2024 Diggaj Coder Private Limited- All rights reserved.
          </div>
          <div class="footer-card-4-icon d-md-flex flex-wrap d-none d-sm-flex mr-sm-n11 mr-md-n1">
            <NuxtLink to="https://www.linkedin.com/company/diggaj-coder/mycompany/" target="_blank">
              <img src="public/images/img/footer/download (2).png" alt="LinkedIn" class="pr-5" />
            </NuxtLink>
            <NuxtLink to="https://x.com/DIGGAJCODER" target="_blank">
              <img src="public/images/img/footer/download.png" alt="X" class="pr-5" />
            </NuxtLink>
            <!-- <NuxtLink to="" target="_blank">
              <img
                src="public/images/img/footer/download (1).png"
                alt="FaceBook" class="pr-5"
              />
            </NuxtLink> -->
            <NuxtLink to="https://www.instagram.com/diggajcoder/" target="_blank">
              <img src="public/images/img/footer/download (3).png" alt="Instagram" class="pr-5" />
            </NuxtLink>
            <!-- <NuxtLink to="" target="_blank">
              <img
                src="public/images/img/footer/download (4).png"
                alt="Youtube" class="pr-5"
              />
            </NuxtLink> -->
            <NuxtLink to="https://whatsapp.com/channel/0029VadSQIHGk1FzEbX6J82G" target="_blank">
              <img src="public/images/img/footer/download (5).png" alt="WhatsApp" class="mr-2" />
            </NuxtLink>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>
.txt-dec-non li a {
  color: #fff;
  text-decoration: none;
}

.footer-page-background {
  /* background-position: top;
  background-image: url("/images/img/footer/Rectangle 57.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
} */
  background-image: url("/images/img/footer/Rectangle 57.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 100%; */
  left: 0;
  margin: 0 auto;
  /* position: absolute; */
  right: 0;
  /* top: 0; */
  width: 100%;
}

.footer-card-1-left,
.footer-card-1-right {
  color: #fff;
  font-weight: 300;
  font-size: 30px;
  line-height: 36px;
}

.footer-card-1-vertical {
  width: 2px;
  height: 180px;
  background-color: #4dfaff;
  margin: 0px 130px 0px 50px;
}

.footer-card-1-vertical2 {
  width: 580px;
  height: 2px;
  background-color: #4dfaff;
}

.footer-card-1-span1 {
  font-weight: 600;
}

.footer-card-1-btn1 {
  color: #14293c;
  padding: 0px 50px 0px 50px;
  background-color: #4dfaff;
}

.footer-card-2 ul:not(:nth-child(3)) {
  flex: 3;
  line-height: 30px;
}

.footer-card-2 ul:first-child {
  flex: 5;
}

.footer-card-2 ul:last-child {
  /* margin: 0px 0px 0px -20px; */
  line-height: 30px;
}

.footer-card-2 ul li {
  margin: 15px 0px 15px 0px;
}

.footer-card-3 {
  color: #fff;
  font-size: 16px;
  font-weight: 300;
}

/* .footer-card-4-icon img {
  margin: 0px 10px;
} */
.footer-termsConditon a {
  text-decoration: none;
  color: white;
}

/* Small devices (portrait tablets and large phones, 576px and up) */
@media (max-width: 767.98px) {

  .footer-card-1-left,
  .footer-card-1-right {
    text-align: center;
  }

  .footer-card-1-vertical2 {
    margin: 60px 0px;
  }

  .footer-card-2 {
    text-align: left;
  }

  .mobile-footers {
    text-align: center !important;
  }

  .footer-card-3 {
    justify-content: center;
  }

  .footer-card-4 {
    justify-content: center;
  }

  .footer-card-4-icon {
    justify-content: center;
    /* margin: 50px 0px; */
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {

  .footer-card-1-left,
  .footer-card-1-right {
    text-align: center;
  }

  .footer-card-2 ul {
    text-align: left;
  }

  .mobile-footers {
    text-align: center !important;
  }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media (min-width: 1200px) {
  .footer-container {
    margin: auto;
    max-width: 1600px !important;
  }
} */
</style>