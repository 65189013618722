<template>
  <div>
    <v-dialog v-model="dialog" width="800" persistent>
      <div
        style="background-color: #ffffff"
        class="d-flex flex-column flex-sm-row popup-login-border"
        wrap
      >
        <div
          style="background-color: #d9e7fd; height: 100%"
          class="d-flex justify-center align-center popup-login-border-left"
        >
          <v-img
            src="/images/LandingPage/Group1.png"
            class="ma-10 popup-login-img mt-4"
          ></v-img>

          <v-btn
            icon="$close"
            variant="text"
            color="#14293C"
            @click="closeLoginDialog"
            class="position-absolute top-0 left-0 mt-md-5 ml-md-5"
          >
            <v-icon left></v-icon>
          </v-btn>
        </div>

        <div
          style="height: 100%"
          class="d-flex flex-column justify-space-between align-left pa-4 pa-sm-10"
        >
          <div class="d-flex align-center">
            <h1 class="mr-2 pb-2">Log In</h1>
            <v-img
              src="/images/LandingPage/logo.png"
              max-width="200"
              max-height="40"
              contain
            ></v-img>
          </div>
          <div>
            <v-form ref="login" v-model="Loginform">
              <p style="color: #5f7588" class="mt-sm-n6 mb-md-5">
                Don't have an account?
                <a href="" @click.prevent="Registerclick">Register</a>
              </p>
              <v-text-field
                class="mt-md-2"
                rounded="lg"
                variant="outlined"
                @keydown.enter="onSubmit"
                placeholder="Enter Your Email Id / Mobile Number"
                v-model.trim="email"
                :rules="[isNotEmpty, validateInput]"
                x
                required
              >
              </v-text-field>

              <v-text-field
                class="mt-2"
                @keydown.enter="onSubmit"
                rounded="lg"
                v-model.trim="password"
                :append-inner-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append-inner="show = !show"
                :readonly="loading"
                :rules="[required]"
                variant="outlined"
                placeholder="Enter your password"
              ></v-text-field>
              <div class="mt-md-2 mt-2">
                <a href="forgetpassword" @click.prevent="showForgetDialog"
                  >Forget Password</a
                >
              </div>
              <div id="recaptcha-container" class="g-recaptcha mb-5 mt-3"></div>
              <div class="mt-md-10 mt-sm-5">
                <v-btn
                  flat
                  block
                  rounded="lg"
                  color="rgba(58, 82, 103, 1)"
                  size="x-large"
                  class=""
                  style="color: white"
                  @click="onSubmit"
                >
                  Log In
                </v-btn>
              </div>
            </v-form>
          </div>

          <div
            class="login-termsConditon mt-3 align-end text-caption"
            style="color: grey"
          >
            By signing up, you agree to
            <strong style="color: #14293c">Terms & Conditions</strong>
            and
            <strong style="color: #14293c"> Privacy Policy</strong>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import { useMainStore } from "../stores/index";
import mixins from "../../mixins/global";
import Toast from "./toast.vue";
export default {
  mixins: [mixins],
  mounted() {
    window.addEventListener("keydown", this.handleEsc);

    this.$nextTick(() => {
      if (typeof grecaptcha !== "undefined") {
        grecaptcha.ready(() => {
          grecaptcha.render("recaptcha-container", {
            sitekey: `${this.$config.public.secreteKey}`,
          });
        });
      } else {
        console.error("reCAPTCHA script not loaded");
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleEsc);
  },
  data() {
    return {
      show: false,
      store: useMainStore(),
      dialog: true,
      Loginform: false,
      email: "",
      password: null,
      loading: false,
    };
  },
  methods: {
    Registerclick() {
      console.log("Register clicked");
      this.store.registerDialog = true;
      this.closeLoginDialog();
    },
    handleEsc(event) {
      if (event.key === "Escape") {
        this.closeLoginDialog();
      }
    },
    isNotEmpty(value) {
      return !!value || "This field is required.";
    },
    validateInput(value) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const phonePattern = /^\d{10}$/;
      if (emailPattern.test(value) || phonePattern.test(value)) {
        return true;
      } else {
        return "Please enter a valid email or a 10-digit number.";
      }
    },
    required(v) {
      return !!v || "Password  is required";
    },
    closeLoginDialog() {
      this.$emit("close");
    },
    async onSubmit() {
      this.$refs.login.validate();
      if (!this.Loginform) {
        // alert("Please fill required fields");
        this.store.triggerSnackbar("Please fill required fields", "info");
        return;
      }
      const recaptchaResponse = grecaptcha.getResponse();
      if (!recaptchaResponse) {
        // alert("Please complete the reCAPTCHA");
        this.store.triggerSnackbar("Please complete the reCAPTCHA", "info");
        return;
      }
      try {
        const LogIndata = {
          username: this.email,
          pwd: this.password,
        };
        let result = await this.post("login/dc/v1", LogIndata, {
          secure: false,
        });
        console.log("otp getting", result);
        if (result) {
          let payload = {
            loginToken: result[1][0].jwt,
            userDetails: result[0][0],
            isLoggedIn: true,
          };
          console.log("this is Payload=", payload);
          this.store.SetToken(payload);
          this.closeLoginDialog();
          this.$router.push("/profile");
        }
      } catch (err) {
        console.log("This ir Error", err);
        // alert("Please Enter valid Username And Password");
        this.store.triggerSnackbar("Something went wrong", "error");
      }
    },
    showForgetDialog() {
      console.log("called");
      this.$emit("showforget");
    },
  },
};
</script>
<style>
.popup-login-img {
  width: 300px;
  height: 276px;
}

.popup-login-border {
  border-radius: 10px 10px 10px 10px;
  height: 640px;
}

.popup-login-border-left {
  border-radius: 10px 0px 0px 10px;
}

@media (max-width: 599.99px) {
  .popup-login-img {
    width: auto;
    height: 159px;
  }

  .popup-login-border-left {
    border-radius: 10px 10px 0px 0px;
  }

  .popup-login-border {
    height: 700px;
  }
}
</style>
