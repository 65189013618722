<template>
  <div>
    <!-- Your application content -->
    <v-snackbar
      v-model="mainStore.snackbarNew.show"
      :color="mainStore.snackbarNew.color"
      :top="true"
      :timeout="3000"
      class="cust_position_snackbar mt-0"
    >
      {{ mainStore.snackbarNew.message }}
      <template v-slot:actions>
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="mainStore.snackbarNew.show = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { useMainStore } from "../stores/index";
export default {
  data: () => ({
    mainStore: useMainStore(),
  }),
};
</script>
<style>
.v-snackbar__wrapper {
  top: 0 !important;
}
</style>
