<template>
  <div class="mb-16">
    <v-app-bar :elevation="3" color="white" class="py-2 px-md-7">
      <template v-slot:prepend>
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          class="d-md-none"
        ></v-app-bar-nav-icon>
      </template>
      <v-app-bar-title>
        <v-img
          id="menu-activator1"
          src="/images/img/Diggaj Coder.svg"
          width="243px"
          height="54px"
          @click="redirecthomePage"
        >
        </v-img>
      </v-app-bar-title>
      <div class="d-flex">
        <ul
          class="d-md-flex header-lists d-none align-center"
          :style="{ listStyle: 'none' }"
        >
          <li>
            <NuxtLink to="/profile">Home</NuxtLink>
          </li>
          <li>
            <NuxtLink to="/Intership">Internship</NuxtLink>
          </li>
          <!-- <li>
            <NuxtLink to="/Bootcamp">Bootcamp</NuxtLink>
          </li> -->
          <!-- <li>
            
            <NuxtLink to="/Sponsorship">Sponsorship</NuxtLink>
          </li> -->
          <li v-if="loggedIn">
            <NuxtLink to="/ReferAndEarn">
              <p>Refer & Earn</p>
            </NuxtLink>
          </li>
          <li>
            <NuxtLink to="https://diggajcoder.substack.com/" target="_blank"
              >Blog</NuxtLink
            >
          </li>
          <!-- <li>
            <NuxtLink to="/FAQs">FAQs</NuxtLink>
          </li> -->
          <!-- <li>
            <v-menu>
              <template v-slot:activator="{ props }">
                <div v-bind="props" style="cursor: pointer;">
                  More <v-icon icon="mdi-chevron-down"></v-icon>
                </div>
              </template>
              <v-list class="mt-5" width="250">
                <v-list-item v-for="(item, index) in moreOption" :key="index" :value="index" router :to="item.route">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </li> -->

          <li>
            <NuxtLink to="/FAQs">FAQs</NuxtLink>
          </li>
          <li>
            <NuxtLink to="/Community">Discord</NuxtLink>
          </li>
          <!-- <li><v-icon icon="mdi-magnify" style="cursor: pointer;"></v-icon></li> -->

          <!-- <li v-if="loggedIn" class="">
            <v-icon id="menu-activator" icon="mdi-bell-outline" size="small" style="cursor: pointer;"
              class="ml-n5"></v-icon>
            <v-menu activator="#menu-activator" v-model="menu" :close-on-content-click="false">
              <v-card flat width="450" class="mt-8" style="max-height: 400px;">
                <div class="d-flex justify-space-between align-center px-4 py-3" style="background-color: #ECF4FF;">
                  <v-card-title class="notification-top-title">
                    Notifications
                  </v-card-title>
                  <div>
                    <NuxtLink to="/Notification"> <v-btn flat color="#D6DFEC" text="View All" class="mr-4"
                        @click="menu = false"></v-btn>
                    </NuxtLink>
                    <v-icon icon="mdi-close" @click="menu = false"></v-icon>
                  </div>
                </div>
                <v-tabs v-model="tab" align-tabs="start" hide-slider mandatory="force" class="ma-5" height="30">
                  <v-tab class="text-capitalize notify-tabs" v-for="(item, i) in notifyTab" :key="i" rounded="xl"
                    border="sm" base-color="#545D66" color="#FFFFFF">
                    {{ item.text }}</v-tab>
                </v-tabs>
                <div>
                  <v-tabs-window v-model="tab">
                    <v-tabs-window-item value="option-1" class="mt-n3">
                      <v-card flat v-for="(item, i) in notifydata" :key="i" color="#EFF4F9" class="ma-3">
                        <div class="d-flex">
                          <div class="d-flex align-center ml-4"><v-img :src="item.image" width="50" height="50"></v-img>
                          </div>
                          <div class="notification-info">
                            <v-card-title v-html="item.title"></v-card-title>
                            <v-card-text>{{ item.subtitle }}</v-card-text>
                          </div>
                        </div>
                      </v-card>
                    </v-tabs-window-item>
                  </v-tabs-window>
                </div>
              </v-card>
            </v-menu>
          </li> -->
          <li v-if="loggedIn" class="">
            <v-menu v-if="loggedIn" rounded="lg">
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props">
                  <v-avatar color="brown" size="small">
                    <v-img alt="User Picture" :src="UserImg"></v-img>
                  </v-avatar>
                </v-icon>
              </template>
              <v-card width="300" flat class="mt-8">
                <v-card-text>
                  <div class="mx-auto text-center">
                    <v-avatar color="brown">
                      <v-img alt="User Picture" :src="UserImg"></v-img>
                    </v-avatar>
                    <h3>{{ this.store.userDetails.first_name }}</h3>
                    <p class="text-caption mt-1">
                      {{ this.store.userDetails.email }}
                    </p>
                    <v-divider class="my-3"></v-divider>
                    <v-btn variant="text" rounded @click="EditUser">
                      Edit Account
                    </v-btn>
                    <v-divider class="my-3"></v-divider>
                    <v-btn
                      v-if="loggedIn"
                      variant="text"
                      rounded
                      @click="logout"
                    >
                      Log Out
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-menu>
          </li>
          <li>
            <v-btn
              v-if="!loggedIn"
              variant="outlined"
              class="header-sign-in d-none d-md-flex py-6"
              color="white"
              flat
              rounded="lg"
              text="Sign in / Log in"
              @click="openLoginDialog"
            >
            </v-btn>
          </li>
        </ul>

        <!-- <v-btn v-if="loggedIn" variant="outlined" class="mr-3 header-sign-in d-none d-md-flex" color="white" flat
          rounded="lg" text="Logout" height="45px" @click="logout">
        </v-btn> -->
        <v-dialog v-model="showregisterDialog" max-width="800px" persistent>
          <div
            style="background-color: #ffffff"
            class="d-flex flex-column flex-sm-row popup-register"
            wrap
          >
            <div
              style="background-color: #d9e7fd; height: 100%"
              class="d-flex justify-center align-center popup-register-left"
            >
              <v-img
                src="/images/LandingPage/Group1.png"
                class="ma-10 popup-login-img mt-4"
              ></v-img>

              <v-btn
                icon="$close"
                variant="text"
                color="#14293C"
                @click="closeRegisterDialog"
                class="position-absolute top-0 left-0 mt-md-5 ml-md-5"
                ><v-icon left></v-icon
              ></v-btn>
            </div>
            <div
              style="height: 100%"
              class="d-flex flex-column justify-space-between pa-4 pa-sm-10"
            >
              <div class="d-flex align-center">
                <h1 class="mr-2 pb-2">Join</h1>
                <v-img
                  src="/images/LandingPage/logo.png"
                  max-width="200"
                  max-height="40"
                  contain
                ></v-img>
              </div>

              <div class="mt-md-n16 mt-sm-n16">
                <v-form ref="registerForm" v-model="registerForm">
                  <p style="color: #5f7588" class="mt-sm-n14">
                    Already Have an account?
                    <a href="" @click.prevent="loginclick">Log in</a>
                  </p>
                  <v-text-field
                    type="tel"
                    rounded="lg"
                    v-model="mobileNumber"
                    :counter="10"
                    :rules="mobileRules"
                    variant="outlined"
                    prepend-inner-icon="mdi-dialpad"
                    placeholder="Enter your Mobile Number"
                    required
                    maxlength="10"
                    class="mt-2 mt-sm-5"
                  ></v-text-field>
                  <p style="color: #5f7588" class="mt-5 mt-sm-n1">
                    We'll send an OTP for Verification.
                  </p>
                  <div
                    id="recaptcha-container-2"
                    class="g-recaptcha my-5"
                  ></div>
                  <v-btn
                    flat
                    block
                    rounded="lg"
                    color="#3a5267"
                    size="x-large"
                    class="mt-sm-8"
                    @click="showOtp"
                    style="color: white"
                  >
                    Request OTP
                  </v-btn>
                </v-form>
              </div>
              <div
                class="header-termsConditon mt-2 mt-sm-5 align-end text-caption"
                style="color: grey"
              >
                By signing up, you agree to
                <NuxtLink to="/terms" @click="closeRegisterDialog">
                  <strong style="color: #14293c">Terms & Conditions</strong>
                </NuxtLink>
                and
                <NuxtLink to="/policy" @click="closeRegisterDialog">
                  <strong style="color: #14293c"> Privacy Policy</strong>
                </NuxtLink>
              </div>
            </div>
          </div>
        </v-dialog>
        <Otp
          v-if="dialogOtpVisible"
          ref="otpComponent"
          @closeOtp="closeOtp"
          @showLogin1="showLogin"
          :verify-prop="this.mobileNumber"
        />
        <Login
          v-if="dialogLoginVisible"
          @close="closeLoginDialog"
          @showforget="showforget"
        />
        <forgetPassword
          v-if="dialogforgetVisible"
          @closeShowforget="closeShowforget"
        />
      </div>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      temporary
      :width="300"
      class="pt-4 d-flex d-md-none"
      :location="$vuetify.display.mobile ? 'left' : 'none'"
    >
      <v-divider></v-divider>
      <v-list density="compact" nav>
        <v-list-item
          class="mr-10"
          v-for="(item, index) in loginItem"
          :key="index"
          :value="index"
        >
          <NuxtLink
            :to="item.route"
            :target="item.target"
            class="custom-nuxtlink"
          >
            <v-list-item-title>{{ item.title }} </v-list-item-title>
          </NuxtLink>
        </v-list-item>
        <v-list-item class="ml-n2">
          <v-btn
            v-if="!loggedIn"
            variant="outlined"
            class="header-sign-in"
            color="white"
            flat
            rounded="lg"
            text="Sign in / Log in"
            height="40px"
            @click="openLoginDialog"
          >
          </v-btn>
          <v-btn
            v-if="loggedIn"
            variant="outlined"
            class="header-sign-in"
            color="white"
            flat
            rounded="lg"
            text="Logout"
            height="40px"
            @click="logout"
          >
          </v-btn>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<style scoped>
.v-tab--selected {
  background-color: #2e81f7;
  box-shadow: 0px 0px 10px 0px #2e81f7;
  color: #ffffff;
}

.notify-tabs {
  margin: 0px 10px 0px 10px;
}

.header-termsConditon a {
  text-decoration: none;
}

.header-lists .router-link-active {
  color: #2e81f7;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}

.header-lists li {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin: 0px 20px;
}

ul li a {
  text-decoration: none;
  color: #000000;
}

.header-sign-in {
  background-color: #2e81f7;
  color: #ffffff;
  text-transform: capitalize;
  /* border-radius: 5px;
  padding: 5px; */
}

.popup-register {
  border-radius: 10px 10px 10px 10px;
  height: 640px;
}

.popup-register-left {
  border-radius: 10px 0px 0px 10px;
}

.popup-login-img {
  width: 300px;
  height: 276px;
}

.custom-nuxtlink {
  text-decoration: none;
  color: #000000;
}

/* notification */

.notification-top-title {
  font-size: 20px;
  font-weight: 900;
  line-height: 30px;
  color: #0057d3;
}

.notification-info .v-card-title {
  color: #14293c;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.notification-info .v-card-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #14293c;
}

.stgcolor {
  color: red;
}

@media (max-width: 599.99px) {
  .popup-login-img {
    width: 100%;
    height: 159px;
  }

  .popup-register-left {
    border-radius: 10px 10px 0px 0px;
  }

  .popup-register {
    height: 700px;
  }
}
</style>
<script>
import Login from "../Login.vue";
import forgetPassword from "../Forgetpassword.vue";
import Otp from "../Otp.vue";
// import axios from "axios";
import mixins from "../../mixins/global";
import { useMainStore } from "../stores/index";
export default {
  mixins: [mixins],
  components: {
    Otp,
    Login,
    forgetPassword,
  },
  data() {
    return {
      UserImg: "/images/HeaderIMG/userPIc.png",
      menu: false,
      menu2: false,
      tab: "option-1",
      notifyTab: [
        { text: "Primary" },
        { text: "Secondary" },
        { text: "Tertiary" },
      ],
      store: useMainStore(),
      registerForm: false,
      mobileNumber: "",
      mobileRules: [
        // (v) => !!v || "Phone number is required",
        // (v) =>
        //   /^\d{10}$/.test(v) ||
        //   "Phone number must be 10 digits and only numbers",
        (v) => {
          if (v) return true;
          return "Number is required";
        },
        (v) => {
          if (/^[0-9-]{10,}$/.test(v)) return true;
          return "Phone number needs to be at least 10 digits.";
        },
      ],
      dialogOtpVisible: false,
      dialogLoginVisible: false,
      dialogforgetVisible: false,
      drawer: null,
      registerDialog: false,
      moreOption: [
        { title: "Service", route: "/Service" },
        // { title: "Job Portal", route: "/JobPage" },
        // { title: "Diggaj Coder Cafe", route: "" },
        // { title: "Diggaj Coder StartUp", route: "" },
        // { title: "Corparate Training", route: "" },
        { title: "FAQs", route: "/FAQs" },
      ],
      notifydata: [
        {
          image: "/images/img/notification/Rectangle 1117.svg",
          title: `<strong class="stgcolor">Anna Szand</strong> joined to Final Presentation`,
          subtitle: "2h ago • Social Media Plan",
        },
        {
          image: "/images/img/notification/Rectangle 11.svg",
          title: `<strong class="stgcolor">Jess Raddon </strong>mention you in Tennis List`,
          subtitle: "4h ago • Hobby List",
        },
        {
          image: "/images/img/notification/Rectangle 11.svg",
          title: `<strong class="stgcolor">Jess Raddon </strong>mention you in Tennis List`,
          subtitle: "4h ago • Hobby List",
        },
        {
          image: "/images/img/notification/Rectangle 11.svg",
          title: `<strong class="stgcolor">Sandra Marx </strong>is requesting to upgrade Plan`,
          subtitle: "12h ago • Hobby List",
        },
      ],
    };
  },
  created() {},
  computed: {
    loggedIn() {
      return this.store.isLoggedIn;
    },
    loginItem() {
      // console.log(this.loggedIn, "login");
      if (!this.loggedIn) {
        return [
          { title: "Home", route: "/Profile", icon: "" },
          // { title: "Profile", route: "/Profile", show: false },
          { title: "Intership", route: "/Intership" },
          // { title: "Bootcamp", route: "/Bootcamp" },
          // { title: "Refer & Earn", route: "/ReferAndEarn", },
          {
            title: "Blog",
            route: "https://diggajcoder.substack.com/",
            target: "_blank",
          },
          // { title: "Service", route: "/Service" },
          // { title: "Job Portal", route: "/JobPage" },
          // { title: "Diggaj Coder Cafe", route: "" },
          // { title: "Diggaj Coder StartUp", route: "" },
          // { title: "Corparate Training", route: "" },
          { title: "FAQs", route: "/FAQs" },
          { title: "Community", route: "/Community" },
        ];
      } else {
        return [
          { title: "Home", route: "/", icon: "" },
          { title: "Profile", route: "/Profile" },
          { title: "Intership", route: "/Intership" },
          // { title: "Bootcamp", route: "/Bootcamp" },
          // { title: "Sponsorship", route: "/Sponsorship" },
          { title: "Refer & Earn", route: "/ReferAndEarn" },
          {
            title: "Blog",
            route: "https://diggajcoder.substack.com/",
            target: "_blank",
          },
          // { title: "Service", route: "/Service" },
          // { title: "Job Portal", route: "/JobPage" },
          // { title: "Diggaj Coder Cafe", route: "" },
          // { title: "Diggaj Coder StartUp", route: "" },
          // { title: "Corparate Training", route: "" },
          { title: " FAQs", route: "/FAQs" },
          { title: "Community", route: "/Community" },
        ];
      }
    },

    showregisterDialog() {
      if (this.store.registerDialog) {
        if (typeof grecaptcha !== "undefined") {
          grecaptcha.ready(() => {
            grecaptcha.render("recaptcha-container-2", {
              sitekey: `${this.$config.public.secreteKey}`,
            });
          });
        } else {
          console.error("reCAPTCHA script not loaded");
        }
      }
      return this.store.registerDialog;
    },
  },

  mounted() {
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    window.addEventListener("keydown", this.handleEsc);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    window.removeEventListener("keydown", this.handleEsc);
  },
  methods: {
    EditUser() {
      navigateTo(`/Profile`);
    },
    handleEsc(event) {
      if (event.key === "Escape") {
        this.closeRegisterDialog();
      }
    },
    redirecthomePage() {
      this.$router.push("/");
    },
    openLoginDialog() {
      console.log("called");
      this.store.registerDialog = true;
      // if(document.getElementById('recaptcha-container-2')){
      // }
    },
    closeRegisterDialog() {
      this.store.registerDialog = false;
    },
    closeLoginDialog() {
      this.dialogLoginVisible = false;
    },
    closeForgetDialog() {
      this.dialogforgetVisible = false;
    },
    showforget() {
      console.log("showforget called");
      this.dialogLoginVisible = false;
      this.dialogforgetVisible = true;
    },
    closeShowforget() {
      this.dialogforgetVisible = false;
    },
    loginclick() {
      console.log("login clicked");
      this.store.registerDialog = false;
      this.dialogLoginVisible = true;
    },
    closeOtp() {
      this.dialogOtpVisible = false;
    },
    showLogin() {
      this.dialogOtpVisible = false;

      // this.dialogLoginVisible = true;
      // this.$router.push("/profile");
    },
    async showOtp() {
      this.$refs.registerForm.validate();
      if (!this.registerForm) {
        this.store.triggerSnackbar("Please Enter Valid Number", "info");
        return;
      }
      const recaptchaResponse = grecaptcha.getResponse();
      if (!recaptchaResponse) {
        // alert("Please complete the reCAPTCHA");
        this.store.triggerSnackbar("Please complete the reCAPTCHA", "info");
        return;
      }
      try {
        console.log("show otp");
        const mobileDetails = {
          // mobileDetails is payload
          mobile_no: this.mobileNumber, //mobileNumber is from v-model and mobile_no is format created from api generated (postman).
        };
        let result = await this.post("signup/dc/v1", mobileDetails, {
          secure: false,
        });
        console.log(result, "signup result");
        if (result) {
          this.dialogOtpVisible = true;
          this.store.registerDialog = false;
        }
        // Trigger the startTimer method on the Otp component
        this.$nextTick(() => {
          this.$refs.otpComponent.startTimer();
        });
      } catch (e) {
        console.log("this is an error", e);
        this.store.triggerSnackbar("Please Enter Valid Number", "error");
      }
    },
    logout() {
      console.log("logout clicked");
      this.menu2 = false;
      this.store.resetStore();
      this.redirecthomePage();
      this.store.triggerSnackbar("Logged Out Sucessfully", "success");
    },
    handleBeforeUnload() {
      this.store.registerDialog = false;
    },
  },
};
</script>
